<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      v-if="isAuthenticated && orders.length > 0"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            fixed
            right
            bottom
            color="primary-light"
            class="reserve-parking mb-1"
          >
            <v-img
              src="/img_layout/order/reserve_parking.svg"
              contain
              height="30"
              width="30"
            />
            <span class="reserve-parking-label">
              Ordini <br />
              in corso
            </span>
          </v-btn>
        </v-fab-transition>
      </template>

      <div class="d-flex flex-column reserve-parking-container" v-if="dialog">
        <v-btn @click.stop="dialog = false" icon class="close">
          <v-icon>$close</v-icon>
        </v-btn>
        <ReserveParking
          v-for="order in orders"
          :key="order.orderId"
          :order="order"
          :reserveParkingSuccess="reserveParkingSuccess"
          :alternativesList="alternativesList"
          :error="error"
          :message="message"
          :description="description"
        />
      </div>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.is-cordova {
  .reserve-parking {
    bottom: 84px;
    padding: 41px;
    left: 2%;
    background-color: var(--v-secondary-base) !important;
    color: $black !important;
    .description {
      display: inline;
    }
    .v-btn__content {
      flex-direction: column;
      display: flex;
      white-space: nowrap;
    }
    .reserve-parking-label {
      font-size: 9px;
      margin-top: 2px !important;
    }
  }
  .reserve-parking-container {
    position: relative;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    color: $black;
    border: none !important;
  }
  .reserve-btn {
    color: $white;
    background-color: var(--v-primary-lighten1);
    border-radius: 25px;
    padding: 24px !important;
    font-size: 14px;
  }
}
.is-cordova.keyboard-open {
  .reserve-parking {
    bottom: 4px;
  }
}

.is-cordova.keyboard-open.platform-ios {
  .reserve-parking {
    bottom: 20px;
  }
}
.is-cordova.platform-ios {
  .reserve-parking {
    bottom: 135px;
  }
}
</style>
<script>
import ReserveParking from "@/components/ReserveParking.vue";

import OrderService from "~/service/orderService";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userLoggedIn: false,
      dialog: false,
      orders: [],
      error: null,
      message: null,
      description: null,
      reserveParkingSuccess: false,
      orderStatusList: [6, 5, 3, 18],
      alternativesList: []
    };
  },
  components: { ReserveParking },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  mounted() {
    global.EventBus.$on("parkReserved", () => {});
    global.EventBus.$on("parkNotReserved", () => {});
    global.EventBus.$on("reservingParkError", () => {});
    global.EventBus.$on("closeDialog", () => {
      this.dialog = false;
    });
  },
  methods: {
    async fetchOrders() {
      let data = await OrderService.getOrders(
        this.pageFilter,
        process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE,
        false
      );
      if (data.orders.length > 0) {
        this.orders = data.orders.filter(order =>
          this.orderStatusList.includes(order.orderStatusId)
        );
      }
    }
  },
  created() {
    if (this.isAuthenticated) {
      this.fetchOrders();
    }
  }
};
</script>
