var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reserve-parking-content"},[_c('div',{staticClass:"locker-qr-code mt-4 order-popup"},[(_vm.order)?_c('div',{class:_vm.reserveParkingSuccess
          ? 'parking-reserved order-status order-status-' +
            _vm.order.orderStatusId
          : 'order-status order-status-' + _vm.order.orderStatusId},[(_vm.error)?_c('div',{staticClass:"errors"},[_c('i',{staticClass:"ion-alert-circled"}),_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('div',{staticClass:"order-content center-xs"},[_c('div',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.message)}}),(_vm.order)?_c('img',{staticClass:"mx-auto",attrs:{"src":_vm.reserveParkingSuccess
              ? '/img_layout/order/order_status_' +
                _vm.order.orderStatusId +
                '_parking.svg'
              : '/img_layout/order/order_status_' +
                _vm.order.orderStatusId +
                '.svg'}}):_vm._e(),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.description)}})]),(_vm.order && _vm.alternativesList.length > 0 && !_vm.reserveParkingSuccess)?_c('div',{staticClass:"order-rows mt-4"},[_vm._m(0),_vm._l((_vm.alternativesList),function(item){return _c('div',{key:item.id,class:'complete-stockout row order-item' + item.type},[(item.q2 == 0)?_c('div',{staticClass:"col-9 text-left"},[_vm._v(" "+_vm._s(item.product.name)+" ")]):_vm._e(),(item.q2 == 0)?_c('div',{staticClass:"col-3 text-right"},[_vm._v(" "+_vm._s(item.q2)+_vm._s(_vm.getSuffix(item.product))+" ")]):_vm._e()])})],2):_vm._e(),_c('v-card-actions',{staticClass:"flex justify-center"},[(
            _vm.order &&
              (_vm.order.orderStatusId == 6 || _vm.order.orderStatusId == 18) &&
              _vm.order.isParkingEnabled &&
              !_vm.reserveParkingSuccess
          )?_c('v-btn',{staticClass:"reserve-btn text-center justify-center mt-4",attrs:{"uppercase":"","text":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.reserveParking()}}},[_vm._v(" Prenota Parcheggio ")]):_vm._e(),(_vm.reserveParkingSuccess)?_c('v-btn',{staticClass:"reserve-btn text-center justify-center mt-4",attrs:{"uppercase":"","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Grazie ")]):_vm._e(),(
            !_vm.reserveParkingSuccess &&
              (!_vm.order ||
                _vm.order.orderStatusId == 5 ||
                ((_vm.order.orderStatusId == 6 || _vm.order.orderStatusId == 18) &&
                  !_vm.order.isParkingEnabled))
          )?_c('v-btn',{staticClass:"reserve-btn text-center justify-center mt-4",attrs:{"uppercase":"","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Va bene, grazie ")]):_vm._e(),(_vm.order.isEditable)?_c('v-btn',{staticClass:"reserve-btn text-center justify-center mt-4",attrs:{"uppercase":"","text":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editOrder(_vm.order.orderId)}}},[_vm._v(" Modifica ordine ")]):_vm._e()],1)],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row order-item-header"},[_c('div',{staticClass:"col-12"},[_vm._v("PRODOTTI NON DISPONIBILI")])])}]

export { render, staticRenderFns }