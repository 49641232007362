<template>
  <div class="reserve-parking-content">
    <div class="locker-qr-code mt-4 order-popup">
      <div
        v-if="order"
        :class="
          reserveParkingSuccess
            ? 'parking-reserved order-status order-status-' +
              order.orderStatusId
            : 'order-status order-status-' + order.orderStatusId
        "
      >
        <div class="errors" v-if="error">
          <i class="ion-alert-circled"></i> {{ error }}
        </div>
        <div class="order-content center-xs">
          <div class="message" v-html="message"></div>
          <img
            v-if="order"
            class="mx-auto"
            :src="
              reserveParkingSuccess
                ? '/img_layout/order/order_status_' +
                  order.orderStatusId +
                  '_parking.svg'
                : '/img_layout/order/order_status_' +
                  order.orderStatusId +
                  '.svg'
            "
          />
          <div class="description" v-html="description"></div>
        </div>
        <div
          v-if="order && alternativesList.length > 0 && !reserveParkingSuccess"
          class="order-rows mt-4"
        >
          <div class="row order-item-header">
            <div class="col-12">PRODOTTI NON DISPONIBILI</div>
          </div>
          <div
            :class="'complete-stockout row order-item' + item.type"
            v-for="item in alternativesList"
            :key="item.id"
          >
            <div class="col-9 text-left" v-if="item.q2 == 0">
              {{ item.product.name }}
            </div>
            <div class="col-3 text-right" v-if="item.q2 == 0">
              {{ item.q2 }}{{ getSuffix(item.product) }}
            </div>
          </div>
        </div>
        <v-card-actions class="flex justify-center">
          <v-btn
            v-if="
              order &&
                (order.orderStatusId == 6 || order.orderStatusId == 18) &&
                order.isParkingEnabled &&
                !reserveParkingSuccess
            "
            class="reserve-btn text-center justify-center mt-4"
            uppercase
            text
            @click.stop.prevent="reserveParking()"
          >
            Prenota Parcheggio
          </v-btn>
          <v-btn
            v-if="reserveParkingSuccess"
            class="reserve-btn text-center justify-center mt-4"
            uppercase
            text
            @click="closeDialog()"
          >
            Grazie
          </v-btn>
          <v-btn
            v-if="
              !reserveParkingSuccess &&
                (!order ||
                  order.orderStatusId == 5 ||
                  ((order.orderStatusId == 6 || order.orderStatusId == 18) &&
                    !order.isParkingEnabled))
            "
            class="reserve-btn text-center justify-center mt-4"
            uppercase
            text
            @click="closeDialog()"
          >
            Va bene, grazie
          </v-btn>
          <v-btn
            v-if="order.isEditable"
            @click.stop.prevent="editOrder(order.orderId)"
            class="reserve-btn text-center justify-center mt-4"
            uppercase
            text
          >
            Modifica ordine
          </v-btn>
        </v-card-actions>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.reserve-parking-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  .pinch-zoom-wrapper {
    flex-grow: 1;
  }
}
.order-popup {
  .cart-detail {
    text-align: center;
    font-size: 16px;
    margin-bottom: 50px;

    i {
      color: $primary;
    }

    strong {
      margin-top: 17px;
      color: $primary;
      display: block;
      font-size: 20px;
    }
    div {
      margin-top: 5px;
      text-transform: capitalize;
    }
  }
  .errors {
    background-color: var(--v-error-base);
    color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  .popup {
    width: calc(100vw - 30px);
    //height: calc(100vh - 30px);
    border-radius: 25px;
  }
  .popup-head {
    border-bottom: none;
  }

  .popup-body {
    height: 100%;
    text-align: center;
  }

  .order-status {
    height: 100%;
    margin: 0px 5px;
    display: flex;
    flex-direction: column;

    .order-content {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin: 20px;
    }

    .order-rows {
      padding: 20px;
    }

    .message {
      color: #000;
      font-weight: 600;
      font-size: 24px;
      padding: 10px;
      line-height: 1;
    }

    img {
      width: 80px;
      margin: auto;
    }

    .description {
      color: #fff;
      font-size: 16px;
      padding: 10px;
      line-height: 1.4;
    }

    .order-item {
      font-size: 12px;
      margin-top: -1px;
      padding: 0px 5px;
      border-bottom: 1px solid #ccc;

      > div {
        padding: 3px 5px;
        line-height: 1.2;
      }

      &.ordered {
        color: #000;
      }
      &.alternative {
        color: #1ab234;
      }
      &.replaced {
        color: #ff0000;
      }

      &.complete-stockout {
        color: #ff0000 !important;
        // text-decoration: line-through;
      }
    }

    .complete-stockout {
      color: #ff0000 !important;
      div {
        color: #ff0000 !important;
      }
    }
    .order-item-header {
      font-weight: 600;
      // border-bottom: 1px solid #ccc;
      white-space: nowrap;
      > div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
      }
    }

    .order-item-legend {
      margin-top: 20px;

      .order-item-header {
        border-bottom: none;
        padding-left: 5px;
      }
      * {
        font-size: 12px;
      }
    }
  }

  .order-status-5 .order-content {
    background-color: #f69679;
    .message {
      color: #fff;
      font-size: 20px;
    }
  }
  .order-status-6 .order-content,
  .order-status-18 .order-content {
    background-color: #efb95b;
    .message {
      color: #fff;
      font-size: 20px;
    }
  }

  .order-status-3 .cart-detail {
    strong {
      color: #fff;
    }
  }

  .order-status-6.parking-reserved .order-content,
  .order-status-18.parking-reserved .order-content {
    background-color: #acd373;
    .message {
      color: #fff;
      font-size: 20px;
    }
  }

  .order-status-3 .order-content {
    background-color: #f69679;
    .message {
      color: #fff;
      font-size: 20px;
    }
  }

  .order-status- .order-content .cart-detail {
    order: -1;
  }

  .close-button {
    text-align: right;
    position: absolute;
    top: 0px;
    right: 10px;
    z-index: 1000;
    padding: 0px !important;
    background: transparent;
    border: none;
  }

  .popup-buttons .button:not(.close-button) {
    display: inline-block;
    flex: initial;
    margin: auto;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 768px) {
    .popup {
      width: calc(100vw - 100px);
      //height: calc(100vh - 100px);
    }

    .popup-buttons {
      height: 120px;
      .button:not(.close-button) {
        height: 75px;
        max-height: 100px;
        font-size: 25px;
      }
    }

    .order-status {
      margin: 0px 15px;

      .message {
        font-size: 35px;
        padding: 20px;
      }

      img {
        width: 160px;
        margin: 15px 0px;
      }

      .description {
        font-size: 25px;
        padding: 20px;
      }
    }
  }
}
</style>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import CatalogService from "~/service/catalogService";
import OrderService from "~/service/orderService";

export default {
  name: "ReserveParking",
  props: {
    order: { type: Object, required: true },
    reserveParkingSuccess: { type: Boolean, required: true }
  },
  data() {
    return {
      alternativesList: [],
      error: null,
      message: null,
      description: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  methods: {
    ...mapActions({
      loadCart: "cart/loadCart"
    }),
    formatDate(date) {
      return this.$dayjs(date).format("D MMMM YYYY");
    },
    formatTime(time) {
      return this.$dayjs(time).format("hh:mm:ss A");
    },
    getSuffix(product, quantity) {
      return CatalogService.getSellingSuffix(product, quantity);
    },
    reserveParking() {
      if (!this.reserveParkingSuccess) {
        this.checkProximity(this.order);
      }
    },
    async editOrder({ orderId }) {
      try {
        var _this = this;
        console.log(_this.order.orderId);
        orderId = _this.order.orderId;
        // this.dialog = false;
        this.closeDialog();
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.confirmOrderEdit")
        });
        if (res) {
          await OrderService.editOrder(orderId);
          this.loadCart();
          this.$router.push({
            name: "Checkout"
          });
        }
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.errorOnOrderEditConfirm")
        });
        return null;
      }
    },
    closeDialog() {
      global.EventBus.$emit("closeDialog");
    },
    async fetchOrder() {
      this.alternativesList = [];

      if (!this.order) {
        this.message = "Non hai ordini in corso";
      } else if (this.order.orderStatusId == 3) {
        this.message =
          "ORDINE n° " + this.order.orderId + "<br/>INVIATO AL NEGOZIO";
        this.description =
          "I nostri addetti si occuperanno della preparazione del tuo ordine.";
      } else if (this.order.orderStatusId == 5) {
        this.message = "PREPARAZIONE ORDINE<br/>n° " + this.order.orderId;
        this.description =
          "I nostri addetti si stanno occupando della preparazione del tuo ordine.";
      } else if (
        this.order.orderStatusId == 6 ||
        this.order.orderStatusId == 18
      ) {
        this.order = await OrderService.getOrder(this.order.orderId, null);
        this.message = "RITIRO ORDINE<br/>n° " + this.order.orderId;
        this.description =
          "Il tuo ordine è stato preparato.<br/>Passa a ritirare la tua spesa.";

        this.items = [];
        for (var s = 0; s < this.order.suborders.length; s++) {
          var suborder = this.order.suborders[s];
          for (var k = 0; k < suborder.suborderItems.length; k++) {
            var item = suborder.suborderItems[k];
            if (!item.orderItemReplacedId) {
              var alternative = null;
              var type = "ordered";
              for (var j = 0; j < suborder.suborderItems.length; j++) {
                var itemAlternative = suborder.suborderItems[j];
                if (
                  itemAlternative.orderItemReplacedId &&
                  item.orderItemId == itemAlternative.orderItemReplacedId
                ) {
                  alternative = itemAlternative;
                  type = "replaced";
                  break;
                }
              }
              let result = this.handleOrderItem(item, type);
              if (result.q2 == 0) {
                this.alternativesList.push(result);
              }
              if (alternative) {
                this.alternativesList.push(
                  this.handleOrderItem(itemAlternative, "alternative")
                );
              }
            }
          }
        }
      }
    },
    handleOrderItem(orderItem, type) {
      var q1 =
        (orderItem.quantity > 0 ? orderItem.quantity : orderItem.weight) || 0;
      var q2 =
        (orderItem.deliveredQuantity > 0
          ? orderItem.deliveredQuantity
          : orderItem.deliveredWeight) || 0;

      return { q1: q1, q2: q2, product: orderItem.product, type: type };
    },
    computeDistanceBetweenPoints(lat1, lon1, lat2, lon2) {
      var p = 0.017453292519943295; // Math.PI / 180
      var a =
        0.5 -
        Math.cos((lat2 - lat1) * p) / 2 +
        (Math.cos(lat1 * p) *
          Math.cos(lat2 * p) *
          (1 - Math.cos((lon2 - lon1) * p))) /
          2;

      return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    },
    async callReserve(order, dist) {
      let res = await OrderService.reserveParking(this.order, dist);
      this.reserveParkingSuccess = res.success;
      if (this.reserveParkingSuccess) {
        this.message = res.template;
        this.description =
          "Recati al parcheggio indicato.<br/>La tua spesa ti aspetta.";
      } else {
        this.message = res.template;
        this.description = "";
      }
    },
    checkProximity(order) {
      var _this = this;
      if (navigator) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            // Prepare position objects
            var from = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            var to = {
              lat: order.shippingAddress.latitude,
              lng: order.shippingAddress.longitude
            };
            // Compute distance from user position to order pick up point
            var dist = _this.computeDistanceBetweenPoints(
              from.lat,
              from.lng,
              to.lat,
              to.lng
            );
            // reserveParkingVar(order, dist);

            _this.callReserve(order, dist);
          },
          function(error) {
            global.EventBus.$emit("navigationError", error);
          },
          {
            timeout: 15000,
            enableHighAccuracy: true
          }
        );
      }
    }
  },
  mounted() {
    this.fetchOrder();
  }
};
</script>
